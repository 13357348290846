.wrapper {
  display: flex;
  justify-content: center;
  max-width: 400px;
  margin: auto 0;
  padding: 20px;

  :global {
    .ant-btn {
      padding: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}
