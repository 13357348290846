.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-height: 180px;
  text-transform: uppercase;
  overflow: hidden;

  :global {
    .ant-statistic-content {
      font-size: 20px;
    }

    @media (max-width: 650px) {
      .ant-card-body {
        padding: 0;
      }

      .ant-statistic-title {
        font-size: 14px;
      }

      .ant-statistic-content {
        font-size: 16px;
      }
    }

    @media (max-width: 575px) {
      .ant-statistic-title {
        font-size: 16px;
      }

      .ant-statistic-content {
        font-size: 24px;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    font-size: 50px;

    @media (max-width: 650px) {
      font-size: 30px;
    }

    @media (max-width: 575px) {
      font-size: 60px;
    }
  }
}

@media (max-width: 650px) {
  .card {
    min-height: 120px;
  }
}