@import "src/variables.scss";

.chart_wrapper {
  width: 99%;
  padding: 20px;
}

.chart {
  border: 1px solid $dark;
  border-radius: 8px;

  h3 {
    margin: 0 !important;
  }
}

.interval_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px 20px;
}

.interval_button {
  font-weight: 500;
  color: $light-gray;
  background-color: $blue;
  text-transform: uppercase;
}