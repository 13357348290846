@import 'src/variables.scss';

.popup {
  :global {
    .ant-select-item-option,
    .ant-select-dropdown .ant-select-item,
    .ant-select-item {
      padding: 5px 7px !important;
    }

    .ant-select-item-option-content {
      display: flex;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.header_light {
  background: #bfc2d0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;

  :global {
    svg {
      width: 22px;
      height: 22px;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding: 0 !important;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .ant-space-item:first-child {
      display: flex;
    }

    .ant-btn {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .ant-switch {
      box-shadow: 0 0 10px 0px $light-gray;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-switch-inner-unchecked {
      margin-top: -29px !important;
    }
  }
}
