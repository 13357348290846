@import "src/variables.scss";

.control_panel {
  display: flex;
  justify-content: space-between;
  margin: 0;
  gap: 10px;
}

.title {
  margin-bottom: 0 !important;
}

.date_col {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
}

.report_button_wrapper {
  display: flex;
  align-items: center;
}

.report_button {
  background: $green;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

@media (max-width: 770px) {
  .date_col {
    margin-bottom: 10px;
  }
}